import $ from "jquery";

var cookieAlertName = 'cookieAlert';

function showCookieAlert(){
	var config = {
		'HTML': '<div id="cookieAlert">' +
					'<p class="cookieMessage">' +
						'Diese Website verwendet Cookies. Durch die Nutzung dieser Webseite erkl&auml;ren Sie sich damit einverstanden, dass Cookies verwendet werden. ' +
						'<a class="cookieMoreLink" href="datenschutz.html">Mehr erfahren</a>' +
					'</p>' +
					'<a class="cookieButton">OK</a>' +
				'</div>',
		'Style': '#cookieAlert{position: fixed; bottom: 0; left: 0; right: 0; background: #14387F; padding: 20px; font-size: 14px; -webkit-box-shadow: 0 -3px 12px rgba(27,45,52,.15); box-shadow: 0 -3px 12px rgba(27,45,52,.15); z-index: 99999;} ' +
				 '#cookieAlert a.cookieButton {display: block; width:110px; height: 34px; padding-top:8px; text-align: center; cursor: pointer; color: #14387F; background: #fff; -webkit-transition: all 155ms ease-in; transition: all 155ms ease-in; margin-top: 10px; margin-left: 0px; margin-right: auto; font-size: 14px; font-weight: bold;} ' +
				 '#cookieAlert a.cookieButton:hover {background-color: #707070;} ' +
				 '#cookieAlert p.cookieMessage {display: block; padding: 7px; margin: 0; color: #fff;} ' +
				 '#cookieAlert .cookieMoreLink{color: #707070;}'
	};

	$('head').append('<style>' + config.Style + '</style>');
	$('body').append(config.HTML);

	$('#cookieAlert .cookieButton').on('click', function(e){
		e.preventDefault();
		e.stopPropagation();
		acceptCookieAlert();
	});
}

function acceptCookieAlert(){
	setCookie(cookieAlertName, 1, 365);
	$('#cookieAlert').remove();
}

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

var d;
function setCookie(cname, cvalue, exdays) {
    d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

// Set cookie for english
$("#eng").click(function(){
	setCookie("eng", "paduno_eng", d);
});

// check Location and set or delete cookie
var currentPath = window.location.pathname;
if (currentPath == "/index_en.html") {
		setCookie("eng", "paduno_eng", d);
} else if (currentPath == "/index.html") {
	setTimeout(function () {
		delete_cookie("eng");
	}, 5000);
}
// check if english cookie is set and redirect from german to english
if (currentPath == "/index.html" && getCookie("eng") == "paduno_eng") {
	  window.location.replace("https://paduno.ch/index_en.html");
}
// delete cookie function
function delete_cookie(name) {
  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
// delete english cookie
$("#ger").click(function(){
	delete_cookie("eng");
});


$(function(){
	if(getCookie(cookieAlertName) != 1){
		showCookieAlert();
	}
	else{
		$('#cookieAlert').remove();
	}
	var getCookies = document.cookie;
});

var scrollLink;

function smoothscroll() {
  scrollLink = $('.scroll');

  // Smooth scrolling
  scrollLink.click(function(e) {
    e.preventDefault();
    $('body,html').animate({
      scrollTop: $(this.hash).offset().top
    }, 1000);
  });
}




function callEmail() {
  $('#mail').click(function(event) {
    var email = 'office@paduno.ch';
    var subject = 'Kontaktaufnahme mit paduno';
    var emailBody = 'Sehr geehrtes paduno-Team, ich benötige weitere Informationen über das von Euch angebotene Leistungsspektrum. Ich freue mich über Eure Kontaktaufnahme. Mit freundlichen Grüßen';
    document.location = "mailto:" + email + "?subject=" + subject + "&body=" + emailBody;
  });
}


$("#navigation__hamburger").click(function(){
	$(this).toggleClass('navigation__hamburger--active');
	$(".content").toggleClass('blur');
	$("#nav").toggleClass('nav--active');
	$("body").toggleClass('nav-active-overflow');
});

$(".navigation__link").click(function(){
	setTimeout(function () {
		$("#navigation__hamburger").removeClass('navigation__hamburger--active');
		$(".content").removeClass('blur');
		$("#nav").removeClass('nav--active');
		$("body").removeClass('nav-active-overflow');
	}, 50);
});

$("#language").click(function(){
	$("#language__select").toggleClass('language__select--active');
});

$(window).on('load', function() {
  callEmail();
  smoothscroll();
});
